import 'bootstrap-sass'

const context = function() {

  const mybutton = document.getElementById("myBtn");

  window.onscroll = function() {scrollFunction()};

  function scrollFunction() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      mybutton.style.display = "block";
    } else {
      mybutton.style.display = "none";
    }
  }

  mybutton.addEventListener('click', function(e) {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  })

  const howToRepayBlock = document.getElementById("how_to_repay")

  if (howToRepayBlock) {
    const reedMoreBtn = document.getElementById("reed_more")
    const reedLessBtn = document.getElementById("reed_less")

    const repayInstraction = document.getElementById("repay_instruction")
  
    reedMoreBtn.addEventListener('click', function(e) {
      e.preventDefault()
  
      reedMoreBtn.style.display = 'none'
      reedLessBtn.style.display = 'inline-block'
      repayInstraction.style.display = 'inline-block'
    })
  
    reedLessBtn.addEventListener('click', function(e) {
      e.preventDefault()
  
      reedLessBtn.style.display = 'none'
      reedMoreBtn.style.display = 'inline-block'
      repayInstraction.style.display = 'none'
      howToRepayBlock.scrollIntoView({
        behavior: 'smooth'
      })
    })
  }
}

$(document).ready(context)
